import React from 'react';
import MoonshotArea from './MoonshotArea';
import Header from '../../components/header/Header';
function Moonshot() {
    return (
      <>
        <Header/>
        <main>
          <MoonshotArea/>
        </main>
      </>
    )
  }
  
  export default Moonshot