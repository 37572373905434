import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react';
import { useState , useEffect } from 'react'
import PaymentButton from './PaymentButton';


require('@solana/wallet-adapter-react-ui/styles.css')

//kết nối và thanh toán
export  default function ConnectBox(){
    
    const { connect, disconnect, publicKey, connected  ,select, sendTransaction} = useWallet(); //
    
    const [subscribe, setSubscribe] = useState(false);

    const handleConnect = () => {
        connect();
    };

    const handleDisconnect = () => {
        disconnect();
    };

    //check user subcribe or not
    useEffect(() => {
        if(publicKey != null){ 
            fetch('https://api.tokencheck.io/user/' + publicKey.toBase58())
                .then(response => {
                    // Xử lý khi nhận được response từ server
                    return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
                })
                .then(data => {
                    // Xử lý dữ liệu đã nhận được từ server              
                    
                })
                .catch(error => {
                  // Xử lý khi gặp lỗi trong quá trình fetch
                    console.log('Fetch Error:', error);
                });
          }
    }, [publicKey]);

    
    return (
        <>
             {connected ? (
                <>
                    {(subscribe == false)?
                        (<div>
                            <div className="font-bold uppercase">Subscribe Pro</div>
                            <div>Subscribe Pro to get all features!</div>
                            <div>Fee : 0.2 SOL/month</div>
                        </div>)
                    :("")}

                    <PaymentButton type="box"/>
                    {/* <button className="border bg-solana px-2 rounded h-10 px-4 py-2" onClick={handlePayment}>Subscribe</button> */}
                    <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors 
                        focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 
                        disabled:pointer-events-none disabled:opacity-50 
                        dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 hover:bg-slate-900/90 
                        bg-slate-50 text-slate-900 dark:hover:bg-slate-50/90 
                        h-10 px-4 py-2 bg-gray-300 text-primary hover:text-slate-50" onClick={handleDisconnect}>Disconnect wallet</button>
                </>
                
            ) : (
                <>
                    <div>
                        <div className="font-bold uppercase">Connect Wallet</div>
                        <div>To see all feature, you need to connect wallet & subscribe Pro!</div>
                    </div>

                    <WalletMultiButton 
                        autoApprove={true}
                        onClick={handleConnect}
                        size="large"
                        style={{
                            background: 'linear-gradient(45deg, #9945ff, #14F195)',
                            color: '#fff',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                    />

                </>
            )}
        </>
    )
}

