import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {varTime} from '../../components/Helper'; 
import Footer from '../../components/Footer';

const HomeArea = () => {
    const [slidesToShow, setSlidesToShow] = useState(6); // Số lượng slides hiển thị mặc định

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setSlidesToShow(3); // Giao diện mobile
        } else {
            setSlidesToShow(6); // Giao diện desktop
        }
    }, []);
    

    const settings = {
        dots: false,
        infinite: true,
        speed: 3000,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: true, // Tự động chạy slide
        autoplaySpeed: 3000, // Thời gian chờ giữa các slide (milliseconds)
        arrows: false,
    };



    const [analysisDay, setAnalysisDay] = useState([]);

    const [chartWidth, setChartWidth] = useState(0);
    const [maxDay, setMaxDay] = useState(20);
    

    const measureDiv = (element) => {
        if (element) {
            var _width = element.offsetWidth;
            var _maxDay = Math.floor(element.offsetWidth / 20); //15 là chiều rộng của 1 cột và 5 - khe giữa 2 cột 
            setMaxDay(_maxDay);
            setChartWidth(_width);
        }
    };



    useEffect(() => {
        // console.log(window.innerWidth);
        analysis_day().finally(() => {
            setInterval(() => {
                analysis_day();
            }, 10000); //10s
        });
       
    }, []);

    async function analysis_day(){
        // const screenWidth = window.innerWidth;
        var day = 60;
        // if(screenWidth > 600) day = 60;
        // else day = 18;

        fetch('https://api.tokencheck.io/analysis/day/' + day)
          .then(response => {
            // Xử lý khi nhận được response từ server
            return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
          })
          .then(data => {
            // Xử lý dữ liệu đã nhận được từ server
              // console.log(data);
              setAnalysisDay(data);
          })
          .catch(error => {
            // Xử lý khi gặp lỗi trong quá trình fetch
            console.log('Fetch Error:', error);
          });
    }


    /*
    const [analysisHour, setAnalysisHour] = useState({});

    useEffect(() => {
        fetch('https://api.tokencheck.io/analysis/hour/24')
          .then(response => {
            // Xử lý khi nhận được response từ server
            return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
          })
          .then(data => {
            // Xử lý dữ liệu đã nhận được từ server
              // console.log(data);
              setAnalysisHour(data);
          })
          .catch(error => {
            // Xử lý khi gặp lỗi trong quá trình fetch
            console.log('Fetch Error:', error);
          });
          
    }, []);
    */

    const [data, setData] = useState([]);

    useEffect(() => {
        token_lastest().finally(() => {
            setInterval(() => {
                token_lastest();
            }, 20000); //20s
        });
        
    }, []);

    async function token_lastest(){
        // setData(null);
        fetch('https://api.tokencheck.io/token/lastest')
            .then(response => {
            return response.json(); 
            })
            .then(data => {
                setData(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    }

    const [tooltipContent, setTooltipContent] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const handleRectClick = (event) => {   
        // Lấy tọa độ chuột khi click vào hình chữ nhật
        const rect = event.target.getBoundingClientRect();
        const mouseX = event.clientX - rect.left;
        const mouseY = event.clientY - rect.top;
        // Cập nhật nội dung và vị trí của tooltip
        setTooltipContent('This is a tooltip');
        setTooltipPosition({ x: mouseX, y: mouseY });
        // alert(mouseY);
    };

    const handleSVGClick = () => {
        // Ẩn tooltip khi click vào bất kỳ nơi nào ngoài rect
        setTooltipContent(null);
    };

    return (
        <div className="container mx-auto md:mt-10 max-sm:mt-4 p-4">
            <div className="w-full md:flex items-center">
                <div className="w-full md:w-1/3 text-center grid gap-4">
                    {/* <img  className="viewer" src="user.png"/> */}
                    <div className="text-3xl">QUICK CHECK <span className="text-highlight">#1</span></div>
                    <div className="text-3xl font-bold">SOLANA TOKENS</div>
                    <div className="text-xl text-highlight">RUG. BURN. LIQUIDITY.</div>
                </div>
                <div className="w-full md:w-1/3 flex max-sm:hidden">
                    <div className="radar">
                        <div className="beam"></div>
                        <div className="dot" style={{ '--x': 0.7, '--y': 0.2 }}></div>
                        <div className="dot" style={{ '--x': 0.2, '--y': 0.3 }}></div>
                        <div className="dot dot-red" style={{ '--x': 0.6, '--y': 0.1 }}></div>
                        <div className="dot dot-red" style={{ '--x': 0.4, '--y': 0.6 }}></div>
                        <div className="dot dot-red" style={{ '--x': 0.9, '--y': 0.7 }}></div>
                    </div>
                </div>
                <div className="w-full md:w-1/3  max-sm:mb-4">
                    {/* <div className="w-full x-board today p-2 rounded-md">
                        <div className="flex mx-auto">
                            <div className="font-semibold text-xl">Rug</div>
                            <div className="text-red-600 text-lg ml-16 font-semibold">{analysisHour.remove}</div>
                            <div className="w-full duration text-sm font-semibold"><div className="float-right">in 24h</div></div>
                        </div>
                        <div className="bar mt-2">
                            <div className="progress">
                                <div className="progress-bar"></div>
                            </div>

                        </div>
                        <div className="pb-4 mt-2">
                            <div className="float-right text-sm text-gray-400 font-medium">{makePercent(analysisHour.remove, analysisHour.amount)}%</div>
                        </div>
                    </div> */}

                    <div className="x-board mt-4 p-2 rounded-md">
                        <div className="flex items-center">
                            <div className="text-xl w-full">Raydium Pair / day</div>
                            <div className="whitespace-nowrap text-md md:pr-4 clock" id="utc-clock">00:00:00 UTC</div>    
                        </div>
                        <div className="chart"  ref={measureDiv}>
                            {/* <div>{chartWidth}</div>
                            <div>{maxDay}</div>
                            <div>{analysisDay.length}</div> */}
                            {tooltipContent?( 
                                <div
                                    className="tooltip"
                                    style={{ left: tooltipPosition.x, top: tooltipPosition.y }}
                                    >
                                    {tooltipContent}
                                </div>
                            ):""}

                            <svg onClick={handleSVGClick}>
                                {
                                    

                                    analysisDay.slice(Math.max(analysisDay.length - maxDay, 0)).map((item, index) => {
                                        const maxAmount = analysisDay.reduce((max, _item) => Math.max(max, _item.amount), analysisDay[0].amount);
                                        const rect_height = Math.floor(100 * item.amount / maxAmount);
                                        const rect_x = 20 * index;
                                        const rect_y = 100 - rect_height;
                                        const rect_width = 15;
                                        return (<>
                                                    <rect 
                                                        className="days-bar" 
                                                        fill="gray"
                                                        width={rect_width} 
                                                        height={rect_height}  
                                                        x={rect_x} y={rect_y} 
                                                        onClick={handleRectClick}
                                                    />
                                                    <text
                                                        x={rect_x + rect_width}
                                                        y={rect_y + rect_height / 2}
                                                        fill="white"
                                                        fontSize="14"
                                                        fontFamily="Arial"
                                                        textAnchor="middle"
                                                        alignmentBaseline="central"
                                                        transform={`rotate(-90, ${rect_x + rect_width / 2}, ${rect_y + rect_height / 2})`}>{item.amount}
                                                    </text>
                                                </>
                                                    
                                        )

                                    })
                                }
                            
                            </svg>

                        </div>
                    </div>
                                
                    <div className="text-center"></div>


                </div>
            </div>

            <div className="w-full mx-auto mt-10">
                <div className="font-semibold text-center text-md mb-6">Recent Burn</div>

                <Slider {...settings}>
                    {
                        data?.map((item, index) => {
                            const var_time = varTime(item.open_time, item.blockTime);
                            const link_token = "/token/" + item.mintA;
                            var status, statusBgColor;
                            if(item.remove === 1) {
                                status = 'Rug';
                                statusBgColor = 'text-[12px] text-gray-600 px-2 rounded-md bg-red-300';
                            }
                            else if(item.burn === 1) {
                                status = 'Burn';
                                statusBgColor = 'text-[12px] text-black px-2 rounded-md bg-good';
                            }
                            else {
                                status = 'New';
                                statusBgColor = 'text-[12px] text-gray-600 px-2 rounded-md bg-gray-300';
                            }

                            return (
                                <div className="cursor-pointer">
                                        <div className="flex justify-center items-center text-[12px]">
                                            <div className={statusBgColor}>{status}</div>
                                        </div>
                                    
                                        <div className="flex justify-center items-center">
                                            <a className="cursor-pointer" href={link_token}>
                                            <div className="flex justify-center items-center mt-2 mb-1">
                                                <img className="inline-block rounded-full object-cover w-8 h-8"
                                                    src={item.token_image} alt={item.token_name}/>
                                            </div>
                                            <div>{item.token_name}</div>
                                            </a>
                                        </div>
                                        <div className="flex justify-center items-center text-[12px] text-slate-400">{var_time}</div>
                                    </div>
                            )
                        })

                    }

                                            
                    {/* Thêm các slide khác vào đây */}
                </Slider>
            </div>
            <Footer/>
        </div>
    );
}

export default HomeArea