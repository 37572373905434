import ConnectButton from './ConnectButton';
// import BalanceDisplay from './BalanceDisplay';
import  {WalletContextProvider} from './WalletContextProvider';

const  WalletHeader = () => {


    return (
            <WalletContextProvider>
                <div className="w-full">
                    <div className="float-right flex">
                        {/* <BalanceDisplay/> */}
                        <ConnectButton/>
                    </div>                    
                </div>
            </WalletContextProvider>
        
    );
};

export default WalletHeader;



