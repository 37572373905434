import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


export default function MenuNavigation2() {
    const [activeItem, setActiveItem] = useState('tokens'); // initialize state
    const location = useLocation(); // get current URL

    useEffect(() => {
        if (location.pathname === '/tokens') {
          setActiveItem('tokens');
        } 
        else if (location.pathname === '/pump') {
            setActiveItem('pump');
        } 
        else if (location.pathname === '/moonshot') {
            setActiveItem('moonshot');
        } 
        
      }, [location]); 


    const handleMenuClick = (event, path) => {
        if (event.button === 1) { 
            window.open(path, '_blank');
        }
        else {
            if (location.pathname === path) {
                window.location.reload();
            } else {
                window.location.href = path;
            }
        }
        
        event.preventDefault();
    };


    return (
        //navigation
        <>
            
            <div className="menu-option whitespace-nowrap">
                <button onMouseDown={(e) => handleMenuClick(e, '/tokens')}  className={activeItem === 'tokens' ? 'active cursor-pointer underline underline-offset-4 text-highlight' : 'cursor-pointer'}>
                    <span className="text-slate-50 hover:font-bold hover:bg-transparent hover:text-slate-50">Raydium</span>
                    
                </button>
            </div>
            <div className="menu-option whitespace-nowrap">
                <button onMouseDown={(e) => handleMenuClick(e, '/pump')}  className={activeItem === 'pump' ? 'active cursor-pointer underline underline-offset-4 text-highlight' : 'cursor-pointer'}>
                    <span className="text-slate-50 hover:font-bold hover:bg-transparent hover:text-slate-50">Pump</span>
                </button>
            </div>

            <div className="menu-option whitespace-nowrap">
                <button onMouseDown={(e) => handleMenuClick(e, '/moonshot')}  className={activeItem === 'moonshot' ? 'active cursor-pointer underline underline-offset-4 text-highlight' : 'cursor-pointer'}>
                    <span className="text-slate-50 hover:font-bold hover:bg-transparent hover:text-slate-50">Moonshot</span>
                </button>
            </div>
            
        </>
    );

}
