import React ,  { useState, useEffect } from 'react';
import Navigation from './Navigation';
import { useHistory, useLocation } from 'react-router-dom';
import WalletHeader from '../wallet/WalletHeader';
import ReactGA from 'react-ga4';

 const Header = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const history = useHistory();
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          history.push(`/token/${searchTerm}`);
        }
    };

    const handleClick = () => {
        history.push(`/token/${searchTerm}`);
    };

    let location = useLocation();

    useEffect(() => {
        // Kiểm tra xem URL có tham số 's' không
        const searchParams = new URLSearchParams(location.search);
        const s = searchParams.get('s');
        if (s) {
          // Gửi sự kiện tới Google Analytics
          ReactGA.event({
            category: 'Traffic',
            action: 'Click Share',
            label: `Share_ ${s}`
          });
        }
    }, [location]);

    

    return (

        <div className="bg-primary border-head-bottom mx-auto md:flex p-2 pl-4 pr-4">
                <div className="w-full md:w-1/4 flex pt-1 max-sm:mb-2  items-center gap-4">
                    <Navigation/>
                    <div className="max-sm:w-full md:hidden">
                        <WalletHeader/>
                    </div>
                </div>


                <div className="w-full md:w-1/2 gap-4 flex items-center">   
                    <div className="search-form md:pl-4 w-full relative">
                        <input type="text" 
                            value={searchTerm}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            placeholder="Token Address" 
                            className="search w-full flex p-1"/>
                        <button className="button" type="submit" id="btn_find" onClick={handleClick}>
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
                </div>

                <div className="w-full md:w-1/4 pt-1">
                    <div className="max-sm:hidden">
                        <WalletHeader/>
                    </div>
                </div>

        </div>
      

    );
}

export default Header