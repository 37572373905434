import React from 'react';
import TokenArea from './TokenArea';
import Header from '../../components/header/Header';

function Token() {
    return (
      <>
        <Header/>
        <main>
        <TokenArea/> 
        </main>
      </>
    )
  }
  
  export default Token