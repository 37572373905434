import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useState } from 'react';
import * as web3 from '@solana/web3.js'
import { WalletSendTransactionError } from '@solana/wallet-adapter-base';


const  PaymentButton = () => {
    const [txSig, setTxSig] = useState('');
    const link = () => {
        return txSig ? `https://explorer.solana.com/tx/${txSig}?cluster=mainnet-beta` : ''
    }

    const { connection } = useConnection();
    const { publicKey,select, sendTransaction } = useWallet();
    const [transactionSignature, setTransactionSignature] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handlePayment = (e) => {
        e.preventDefault()
        if (!connection || !publicKey) { return }

        const sol_amount = 0.01;
        const recipientPubKey = new web3.PublicKey("5NuMfsA75666pAMibhRMd5Dh7X14WMNAt15169wL4wWd")


        try{
            const transaction = new web3.Transaction()
            const sendSolInstruction = web3.SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: recipientPubKey,
                lamports: LAMPORTS_PER_SOL * sol_amount
            })
            
            transaction.add(sendSolInstruction);

            sendTransaction(transaction, connection)
                .then(signature => {
                    // setTxSig(sig);
                    setTransactionSignature(signature);
                    setMessage('Transaction sent successfully');
                    // subscribe
                    subscrible(publicKey.toBase58(), signature, sol_amount);

                })
                .catch((err) => {

                    if (err instanceof WalletSendTransactionError && err.message.includes('User rejected the request')) {
                      setError('You have rejected the request.');
                    } else {
                      setError(`Transaction failed: ${err.message}`);
                    }
                    console.log(err); // Log the error for debugging purposes
                });

        }
        catch(err){
            console.log(err.message);
        }
    };

    function subscrible(address, signature, payment){
        var params = {signature : signature, payment : payment};
        fetch('https://api.tokencheck.io/user/subscribe/' + address,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
        .then(response => {
            // Xử lý khi nhận được response từ server
            return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
        })
        .then(data => {
            // Xử lý dữ liệu đã nhận được từ server              
            
        })
        .catch(error => {
            // Xử lý khi gặp lỗi trong quá trình fetch
            console.log('Fetch Error:', error);
        });
    }

    return (
        <button className="color-good hover:font-bold hover:bg-transparent hover:text-slate-50" onClick={handlePayment}>[Subscribe]</button>
    )
    
}

export default PaymentButton;

