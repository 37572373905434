import React from 'react';
import PumpsArea from './PumpsArea';
import Header from '../../components/header/Header';
function Pumps() {
    return (
      <>
        <Header/>
        <main>
          <PumpsArea/>
        </main>
      </>
    )
  }
  
  export default Pumps