

export  function shortAddress2(address) {
    if(address == null || address.length < 10) return "";
    const firstForChars = address.substr(0,2);
    const lastForChars  = address.substr(-2);
    return firstForChars + "..." + lastForChars;
};

  
export  function tokenLink(address){ //account tx token
    return '/token/' + address;
}

export  function solscanLink(address, type ){ //account tx token
    return 'https://solscan.io/'+ type + '/' + address;
}

export  function shortAddress(address) {
    if(address == null || address.length < 10) return "";
    const firstForChars = address.substr(0,5);
    const lastForChars  = address.substr(-4);
    return firstForChars + "..." + lastForChars;
};

export function shortTokenLink(address, type){
     if(address == null || address.length < 10) return "";
     const short_Token = shortAddress(address);
     const sol_scanLink = solscanLink(address, type);
     return (<a className="text-link" target="_blank" rel="noreferrer"  href={sol_scanLink}>{short_Token}</a>);
}



export function shortTokenLink2(address, type){
    if(address == null || address.length < 10) return "";
    const short_Token =  shortAddress2(address);
    const sol_scanLink = solscanLink(address, type);
    return (<a className="text-link" target="_blank" rel="noreferrer"  href={sol_scanLink}>{short_Token}</a>);
}

export function shortMintAuthorityLink(address, type){
    if(address == null || address.length < 10) return "-";
    const short_Token =  shortAddress2(address);
    const sol_scanLink = solscanLink(address, type);
    return (<a className='color-normal' target="_blank" rel="noreferrer"  href={sol_scanLink}>{short_Token}</a>);
}

export  function extensionLink(link){ //account tx token
    if(link == null) return "";
    return (<a className="underline underline-offset-2" target="_blank" rel="noreferrer"  href={link}>{link}</a>);
}

export  function extensionLink2(link, name){ //account tx token
    if(link == null) return "";
    return (<a className="underline underline-offset-2 whitespace-nowrap" target="_blank" rel="noreferrer"  href={link}>{name}</a>);
}

export  function extensionLink3(link, subName){ //account tx token
    if(link == null) return "";
    return (<><a className="underline underline-offset-2" target="_blank" rel="noreferrer"  href={link}>{link}</a> <span className="text-moonshot">({subName})</span></>);
}

//time2 dùng để backup cho time1 nế time1 bằng NULL
export function varTime(time1, time2 = null){ //open_time block_time
    var time;
    if(time1 > 0){
        time = time1;
        if(time1 < 10**9) time = time2;
    } 
    else if(time2) time = time2;

    if(time === 0 || time == null) return "-";

    const current = new Date();
    const current_unix = current.getTime()/1000;

    var ending, distance_time;
    if(current_unix > time){
        distance_time = current_unix - time;
        ending = 'ago';
    }
    else {
        distance_time = time - current_unix;
        ending = 'next';
    }

    var express_time = '';
    if(distance_time < 60) express_time = Math.floor(distance_time)+ "s ";
    else if(distance_time < 60 * 60) express_time = Math.floor(distance_time/60) + "' ";
    else if(distance_time < 24 * 60 * 60)  express_time = Math.floor(distance_time/3600) + "h ";
    else express_time = Math.floor(distance_time/24/3600) + "d ";

    if(ending === 'next')
        return (<span className="color-normal">{express_time} {ending}</span>) ;
    else 
        return (<>{express_time} {ending}</>) ;
}

export function varTime2(time){
    if(time == null || time === "") return '-';
    const current = new Date();
    const current_unix = current.getTime()/1000;
    const distance_time = current_unix - time;
    var express_time = '';

    if(distance_time < 60) express_time = Math.floor(distance_time)+ "s ";
    else if(distance_time < 60 * 60) express_time = Math.floor(distance_time/60) + "' ";
    else if(distance_time < 24 * 60 * 60)  express_time = Math.floor(distance_time/3600) + "h ";
    else express_time = Math.floor(distance_time/24/3600) + "d ";
    return express_time ;
}

export function utcTime(time){
    const date = new Date(time * 1000); 
                    const hours = date.getHours();
                    const minutes = date.getMinutes();
                    const seconds = date.getSeconds();
    return hours + ":" + minutes + ":" + seconds  + " UTC";// new Date(item.open_time * 1000);
}

export function distanceTime(time1, time2){
    var ago = time1 - time2;
    var ago_time = '';
    if(ago < 60) ago_time = ago + "s";
    else if(ago < 60 * 60) ago_time = Math.floor(ago/60) + "m";
    else if(ago < 24 * 60 * 60)  ago_time = Math.floor(ago/3600) + "h";
    else ago_time = Math.floor(ago/24/3600) + "d";
    return ago_time;
}



export function tokenNumber(res){
    if(res == null) return "-";
    const value = res.value;
    if(value == null) return "-";
    if(!value.hasOwnProperty("data")) return "";
    const data = value["data"];
    if(!data.hasOwnProperty("parsed")) return "";
    const parsed = data["parsed"];
    if(!parsed.hasOwnProperty("info")) return "";
    const info = parsed["info"];

    const supply = info["supply"];
    const decimals = info["decimals"];
    const true_supply =  Math.floor(supply / 10 ** decimals);
    return true_supply.toLocaleString('en-US');
}

export function realSupply(supply, decimals){
    if(decimals == null) return 0;
    if(supply == null) return 0;
    const true_supply = Math.floor(supply / 10 ** decimals);
    return true_supply.toLocaleString('en-US');
}

export function solNumber(lamports, showZero){
    if(lamports == null) return "";
    if(lamports === 0 && showZero === false) return "";

    const sol = lamports / 10 ** 9;

    if (Number.isInteger(sol)) {
        return sol.toLocaleString('en-US');
    }

    var new_sol; 
    if (sol >= 0.1) 
        new_sol =  sol.toFixed(1);
    else if (sol >= 0.01) 
        new_sol = sol.toFixed(2);
    else 
        new_sol = sol.toFixed(3);

    return new_sol.toLocaleString('en-US');
}


//keyword : name, symbol (code), uri
export function getMeta(keyword, value){
    if(value == null) return "";
    if(!value.hasOwnProperty("data")) return "";
    const data = value["data"];
   
    return data[keyword];
}

export function getMetaContent(keyword, value, dexMeta = null){

    if(dexMeta != null){
        switch(keyword){
            case "twitter" :
            case "telegram" :
            case "website" : 
                const link =  dexMeta[keyword];
                if(link != null) //return extensionLink3(link , "Dexscreener");
                    return extensionLink(link);
                break;
            default : return null;
                
        }
    }


    if(value == null) return "";
    // const value = x.uri_content;
    switch(keyword){
        case "description" : 
            const description =  value[keyword];
            if(description !== null && description !== "") return description;
            else return "-";
           
        case "twitter" :
        case "telegram" :
        case "website" : 
            if(value.hasOwnProperty(keyword)){
                const link =  value[keyword];
                if(link == null) return "-";
                return extensionLink(link);
            }

            if(value.hasOwnProperty("extensions")) {
                const extensions = value["extensions"];
                const link =  extensions[keyword];
                if(link == null) return "-";
                return extensionLink(link);
            }
            return  "-";
        default:
            return  "";
    }
}

export function beautifyToken(value){
    if(value == null) return;
    if(value >= 1){
        const value2 = value.toFixed(0);
        const round = Math.floor(value2);
        
        return round.toLocaleString('en-US');
    }
    else return value.toFixed(2);
    
}

export function beautifyCap(value){ // dollar
    if(value == null) return;

    if(value >= 1000000){
        const m_number = value/10**6;
        return '$' + m_number.toFixed(2) + "M";
    }
    else if(value >= 1000){
        const k_number = value/1000;
        return '$' + k_number.toFixed(1) + "k";
    }
    else return '$' + value.toFixed(0);
    
}


export function makePercent(part, total){
    if(part == null) return 0;
    if(total == null) return 0;

    if(total === 0 ) return 0;
    const value = 100 * part / total;
    return value.toFixed(1);
}

export function extractSPL(instruction){
    if(instruction == null) return null;
    const instructions = instruction["instructions"];
    for(const ins of instructions){
        if(ins.type === "mintTo") {
            // return (Math.floor(ins.amount / 10 ** 9)).toLocaleString('en-US');
            return ins.amount;
        }
    }

    return null;
}

export function firstWord(slug){
    if(slug == null) return "";
    
    const words = slug.split("-");
    const capitalizedWords = words.map(word => { return word.charAt(0).toUpperCase() + word.slice(1);});
    // return capitalizedWords.join(" ");
    return capitalizedWords[0];
}

export function amountTransfer(instructions, sourceX){
    for(const ins of instructions){
        if(ins.source === sourceX) return ins.amount;
    }

    return null;
}

export function extractTokenCreatorName(obj){
    if(obj == null) return "-";
    if(typeof obj === 'string' ) return obj;
    if(obj.hasOwnProperty("name")) return obj["name"];

    return "-";
}

export function amountToken(data, mint){
    if(data == null) return null;
    for(const obj of data){
        if(obj["mint"] === mint) return obj["amount"];
    }

    return null;
}

export function typeMint(instructions){
    for(const ins of instructions){
        if(ins.hasOwnProperty("mint")) return ins.type;
    }

    return null;
}

export function amountMint(instructions){
    for(const ins of instructions){
        if(ins.hasOwnProperty("mint")) {
            if(ins.type === "burn") return ins.amount;
            if(ins.type === "burnChecked"){
                return ins.tokenAmount.amount;
            }
        }
    }

    return null;
}

export function graphHolders(TokenOwners, Supply){
    if(TokenOwners == null) return ;
    // console.log(Supply);

    var output = [];
    var sum = 0;
    for(const item of TokenOwners){
        
        const value = parseInt(item.amount);
        sum += value;

        output.push({"name" : item.address, "value" : value});
    }

    if(sum < Supply){
        const other = Supply - sum;
        output.push({"name" : "Other", "value" : other});
    }
    return output;
}

export function percentHolders(TokenOwners, Supply , poolAddress ){
    if(TokenOwners == null) return ;

    // console.log(Supply);

    var sum = 0;
    for(const item of TokenOwners){
        if(item.address !== poolAddress){
            sum += parseInt(item.amount);
        }
    }
    return Math.floor(100 * sum / Supply);
}

export function externalMarket(mint_address, TokenCreator, pump = 0){
    
    if(TokenCreator == null) return ;

    const name = extractTokenCreatorName(TokenCreator);
    if(name === "-") return;
    if(name === "https://pump.fun" && pump === -1) return ;

    
    if(name === "https://pump.fun") {
        const link = "https://pump.fun/" + mint_address;
        return (<a target="_blank" rel="noreferrer" className="mr-2 underline" href={link}>Pump.fun</a>);
    }
    else return ;

}   


export function rateCreator(TokenCreator, pump){
    if(TokenCreator == null) return ;
    const name = extractTokenCreatorName(TokenCreator);

    if(name === "https://pump.fun" && pump === -1){
        // return (<div className="text-right"><i className="fa-solid fa-circle text-xs color-alarm"></i><div className="text-xs">Fake Pump</div></div>)
        return (<><i className="fa-solid fa-circle text-xs color-alarm"></i><span className="text-xs ml-2">Fake Pump</span></>)

    }
}

export function externalRaydium(mint_address, isRaydium){
    if(isRaydium === 1){
        const link_raydium = 'https://v2.raydium.io/swap/?outputCurrency=' + mint_address + '&inputCurrency=sol';
        return (<a href={link_raydium} target="_blank"  rel="noreferrer" className="mr-2 underline">Raydium</a>);
    }
    else return;
}

export function externalDexscreener(mint_address, marketDex){
    if(marketDex != null){
        const link_dexscreener = 'https://dexscreener.com/solana/' + mint_address;
        return (<a href={link_dexscreener} target="_blank" rel="noreferrer " className="mr-2 underline">Dexscreener</a>);
    }
    else return;
}


export function externalMarket2(TokenCreator, mint_address){
    if(TokenCreator == null) return ;
    const name = extractTokenCreatorName(TokenCreator);
    if(name === "-") return;
    else {
        if(name === "https://pump.fun") {
            const link = "https://pump.fun/" + mint_address;
            return (<a target="_blank" rel="noreferrer" className="mr-2 underline" href={link}>Pump.fun</a>);
        }
        else return name;
    }
}

export function ratioChange(number_new, number_old){
    if(number_old == null || number_old === 0 || number_new == null || number_new === 0 ) return "";

    if(number_new >= number_old ){
        const ratio_float =  number_new/number_old;
        if(ratio_float >= 2){
            const ratio_round = ratio_float.toFixed(1);
            return (<div className="color-good"><i className="fas fa-arrow-up md:hidden"></i> X{ratio_round} <i className="fas fa-arrow-up max-sm:hidden"></i></div>);
        }
        else{
            const ratio_percent = 100 * (number_new/number_old - 1);
            const ratio_percent_round = ratio_percent.toFixed(0);
            return (<><i className="fas fa-arrow-up color-good md:hidden"></i> +{ratio_percent_round}% <i className="fas fa-arrow-up color-good max-sm:hidden"></i></>);
        }
       
    }
    else {
    
        const ratio_percent = 100 * ( 1- number_new/number_old);
        const ratio_percent_round = ratio_percent.toFixed(0);
        return (<><i className="fas fa-arrow-down text-red-500 md:hidden"></i><span className="text-red-500"> -{ratio_percent_round}% </span> <i className="fas fa-arrow-down text-red-500 max-sm:hidden"></i></>);
    }
}

export function rateTopHolders(percentHolders){
    if(percentHolders >= 40){
        // return (<div className="text-right"><i className="fa-solid fa-circle text-xs color-alarm"></i><div className="text-xs">Too<br/>Large</div></div>)
        return (<><i className="fa-solid fa-circle text-xs color-alarm"></i><span className="text-xs ml-2">Too Large</span></>)

    }
}


export function rateMintAuthority(mintAuthority){
    if(mintAuthority != null) {
        return (<><i className="fa-solid fa-circle text-xs color-alarm"></i><span className="text-xs ml-2">Mintable</span></>)
    }
}

export function rateSPLOwner(SplOwnerPercent){
    if(SplOwnerPercent > 80){
        // return (<div className="text-right"><i className="fa-solid fa-circle text-xs color-alarm"></i><div className="text-xs">Hold<br/>SPL</div></div>)
        return (<><i className="fa-solid fa-circle text-xs color-alarm"></i><span className="text-xs ml-2">Hold SPL</span></>)

    }
}

export function showRateAll(dataMint, mintAuthority, SplOwnerPercent, removeLiquid){
    if(dataMint != null){
        if(dataMint.burn === 1){
            return (<div className="flex items-center  justify-center"><i className="fa-solid fa-circle text-xs color-good mr-2"></i><div className="text-xs">Burn</div></div>)
        }

        if(dataMint.remove === 1){
            return (<div className="flex items-center  justify-center"><i className="fa-solid fa-circle text-xs color-bad mr-2"></i><div className="text-xs">Remove Liquidity</div></div>)
        }
    }
    if(removeLiquid > 0.9){
        return (<div className="flex items-center  justify-center"><i className="fa-solid fa-circle text-xs color-bad mr-2"></i><span className="text-sm">Remove Liquidity</span></div>)
    }

    if(SplOwnerPercent > 80){
        return (<div className="flex items-center  justify-center"><i className="fa-solid fa-circle text-xs color-alarm mr-2"></i><span className="text-sm">Rug Risk</span></div>)
    }

    if(mintAuthority != null){
        return (<div className="flex items-center  justify-center"><i className="fa-solid fa-circle text-xs color-alarm mr-2"></i><span  className="text-sm">Mint Risk</span></div>)
    }

    // 
}

export function showRateToken(burn_block, burn, remove, risk){

    
    if(burn === 1 || burn_block === 1) {
        return (<div className="flex items-center"><i className="fa-solid fa-circle text-xs color-good mr-2"></i><span className="text-sm">BURN</span></div>)
    }


    if(remove === 1){
        return (<div className="flex items-center"><i className="fa-solid fa-circle text-xs color-bad mr-2"></i><span className="text-sm">RUG!</span></div>)
    }

    
    // if(risk === 1){
    //     return (<div className="flex items-center"><i className="fa-solid fa-circle text-xs  color-alarm mr-2"></i><span className="text-sm">Risk</span></div>)

    // }
    return (<div className="flex items-center"><i className="fa-solid fa-circle text-xs  color-alarm mr-2"></i><span className="text-sm">RISK</span></div>)
}

export function showBurn(burn_block, burn){

    
    if(burn === 1 || burn_block === 1) {
        return (<div className="flex items-center"><i className="fa-solid fa-circle text-xs color-good mr-2"></i><span className="text-sm">Burn</span></div>)
    }
    else {
        return (<div className="flex items-center"><i className="fa-solid fa-circle text-xs color-alarm mr-2"></i><span className="text-sm">No Burn</span></div>)
    }
}

export function showRateMintAuthority(mintAuthority){
  if(mintAuthority !=  null){
        return (<div className="flex items-center"><i className="fa-solid fa-circle text-xs color-alarm mr-2"></i><span className="text-sm">Mintable</span></div>)
  }  
  else return ('');
  
}

export function showRateDexpaid(dexpaid, moonshot = 0){

    if(dexpaid === 1 || moonshot === 1){
        return (<div className="flex items-center"><i className="fa-solid fa-circle text-xs text-green-500 mr-2"></i><span className="text-sm">PAID</span></div>)
    }
    else return ('');
}



export function check_dexpaid(json){
    const pair_raydium = checkDexId(json, "raydium");

    if(pair_raydium != null){
        if(pair_raydium.hasOwnProperty("info")){
            return 1;
        }
    }

    return -1;
}

export function checkDexId(json, _dexId){
    const pairs = json?.pairs;
    if(pairs == null) return null;
 
    for(const pair of pairs){
        if(pair.dexId === _dexId) return pair;
    }

    return null;
}



export function getDexMeta(pair){
    try{
        if(pair == null) return null;

        const info = pair["info"];
        if(info == null) return null;
        let output = {};
        const websites = info["websites"];
        let website = null;
        if(websites.length !== 0) website = websites[0]["url"];
        output["website"] = website;

        const socials = info["socials"];
        for(const x of socials){
            output[x["type"]] = x["url"];
        }

        // console.log(output);
        return output;
    }
    catch(ex){
        return null;
    }
}

export function dollarCap(dollar){
    if(dollar == null) return;
    const K_dollar = dollar/1000;
    return K_dollar.toFixed(1) + "K";
}

export function pumpProfile(username, wallet){
    const profile_link = 'https://pump.fun/profile/' + wallet;

    if(username != null){
        return (<a target="_blank" className="border border-slate-400 bg-green-700 rounded-md pl-1 pr-1 text-sm" href={profile_link}>{username}</a>);
    }
    else {
        const short_name = wallet.substr(0,6);
        return (<a target="_blank" className="border border-slate-400 bg-blue-700 rounded-md pl-1 pr-1  text-sm" href={profile_link}>@{short_name}</a>);
    }
}

export function beautifyPercent(value){
    if(value == null) return ;
    if(value < 0)
        return (<span className="text-red-500">{value.toFixed(0)}%</span>);
    else 
    return (<span className="text-green-500">{value.toFixed(0)}%</span>);
}