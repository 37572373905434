import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Moonshot from './pages/moonshot/Moonshot';
import Pumps from './pages/pumps/Pumps';
import Tokens from './pages/tokens/Tokens';
import Token from './pages/token/Token';
import Home from './pages/home/Home';

import Analysis from './pages/analysis/Analysis';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-SFB7S88PVX'); //G-SFB7S88PVX
// import ScrollToTop from 'react-scroll-to-top';

function App() {
  //google analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);


  return (
    <div className="App">
      <Router>
          <Switch>
              <Route path="/" exact component={Home}/> 
              <Route path="/tokens" component={Tokens} />
              <Route path="/pump" component={Pumps} />
              <Route path="/moonshot" component={Moonshot} />

              <Route path="/token/:mint_address" component={Token} />

              <Route path="/analysis" component={Analysis} />

          </Switch>
      </Router>
    </div>
  );
  

}

export default App;
