import { useConnection, useWallet } from '@solana/wallet-adapter-react';

export default function WalletDisplay(){


    const { publicKey } = useWallet();

    return (
         
            publicKey?
                (
                    <> 
                        <div className="font-bold">Your Wallet</div>
                        <div className="text-xs sm:text-sm border border-white rounded p-2">{publicKey.toBase58()}</div>
                    </>
                )
            :("")   
    )
}

