
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import * as web3 from '@solana/web3.js'
// import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { useMemo } from "react";
import React, { createContext, useContext, useState, useEffect } from 'react';


const WalletContext = createContext();

export const WalletContextProvider = ({ children}) => {
    const wallets = useMemo(() => [new PhantomWalletAdapter()], [])
    const endpoint = 'https://mainnet.helius-rpc.com/?api-key=ed893bb8-1216-4383-a713-c072fa745c81';

    const [walletAddress, setWalletAddress] = useState(null);


    return (
      <WalletContext.Provider value={{walletAddress, setWalletAddress}}>
          <ConnectionProvider endpoint={endpoint} >
            <WalletProvider wallets={wallets} autoConnect={true}>
              <WalletModalProvider>
                { children }
              </WalletModalProvider>
            </WalletProvider>
          </ConnectionProvider>
      </WalletContext.Provider>
    );
}

// const endpoint = web3.clusterApiUrl('mainnet-beta');
// const wallets = useMemo(() => [], []);

export const useWalletContext = () => useContext(WalletContext);

