import React, { useState, useEffect } from 'react';
import {shortAddress, varTime, solNumber, makePercent } from '../../components/Helper';
import Modal from '../../components/wallet/Modal';
import { useHistory } from 'react-router-dom';
import Navigation2 from '../../components/header/Navigation2';

/* eslint-disable react-hooks/exhaustive-deps */
const PumpsArea = () => {
    const TEST = 0;

    const [dataList, setDataList] = useState([]);
    const [page, setPage] = useState(1);

    const [filter, setFilter] = useState({});
   
    const nextPage = () => {setPage(page + 1)};
    const prevPage = () => {if(page > 1) setPage(page - 1)};

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {setIsModalOpen(true);  }
    const closeModal = () => {setIsModalOpen(false); }

    let history = useHistory();

    const openLink = (event, mintToken) => {
      if (event.button === 1) { 
          window.open('/token/' + mintToken, '_blank');
      }
      else  history.push('/token/' + mintToken);

      event.preventDefault(); // Prevent default behavior (if any)
    }

    const doubleClick = (event, mintToken) => {
      history.push('/token/' + mintToken);
      event.preventDefault(); // Prevent default behavior (if any)
    }


     /*******************************************
     * AREA : FILTER 
     *******************************************/

     const [checkedItems, setCheckedItems] = useState({
      king_hill: true,
      new_token: false,
      complete : false
    });

    const handleChange = (event) => {
        const { name, checked } = event.target;
       
        setPage(1);

        setCheckedItems({
          ...checkedItems,
          [name]: checked,
        });
        

        if(checked){
          setFilter({ ...filter, type : name});

          var new_filter = {king_hill: false, new_token: false};
          new_filter[name] = true;
          setCheckedItems(new_filter);
        }
        else {
          setFilter({});
        }
        // openModal();
    };


     /*******************************************
     * AREA : LOAD DB 
     *******************************************/

    useEffect(() => {
        loadTokens();
    }, [page, filter]);


    function loadTokens(){
      const limit = 15;
      const offset = limit * (page-1);

      const _filter_type = filter_type();
      let sort;
      if(_filter_type === "king_hill") sort = "king_of_the_hill_timestamp";
      else if(_filter_type === "new_token") sort = "created_timestamp";
      else if(_filter_type === "complete") sort = "king_of_the_hill_timestamp";

      const params = {"offset" : offset , "limit" : limit, "sort" : sort , "order" : "DESC"}
      params["filter"] = filter;

      fetch('https://api.tokencheck.io/token/pump',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            }
          )
          .then(response => {
            return response.json(); 
          })
          .then(response => {
              // const success = response.success;
              const data  = response.data;
              if(data) setDataList(data);
          })
          .catch(error => {
            console.log('Fetch Error:', error);
          });
    }

  
    /*******************************************
     * AREA : REQUEST LIQUIDITY LIST
     *******************************************/

    const [items, setItems] = useState({});

    const addItem = (key, value) => {
      setItems(prevItems => ({ ...prevItems, [key]: value }));
    };

    const removeItem = (key) => {
      const { [key]: _, ...rest } = items;
      setItems(rest);
    };

    const updateItem = (key, newValue) => {
      setItems(prevItems => ({ ...prevItems, [key]: newValue }));
    };

    const getItem = (key) => {
      if(items) return items[key];
    };


    useEffect(() => {
      // token_liquidity();
      // const intervalId = setInterval(() => {
          
        token_liquidity();

      // }, 10000);
    
    }, [dataList]);

    async function token_liquidity(){
      if(TEST === 1) return;
      if(dataList != null){
          for(const index in dataList){
              const item = dataList[index];
              fetch_liquidity(item, index);
          }
        }
    }

    function fetch_liquidity(item, index){
      var params = {liquidityB : item.bonding_curve ,  mint : item.mint, index : index, page : page }; //liquidityA : item.associated_bonding_curve,
      console.log(params); 
      fetch('https://api3.tokencheck.io/liquidity/',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            }
        )
        .then(response => {
            return response.json(); 
        })
        .then(response => {
            // const success = response.success;
            const data  = response.data;

            if(data != null) updateItem(item.bonding_curve, data);
            // setLiquidityToken(data);
        })
        .catch(error => {
          console.log('Fetch Error:', error);
        });

    }


    function getLiquiditySOL(liquidityB){
      if(liquidityB != null &&  items != null) {
        const data = getItem(liquidityB)
        return data?solNumber(data.liquidityBAmount) : 0;
      }

      return 0;
    }

    function calculateCurve(liquidityB, complete = 0){
      if(complete === 1) return (<span className='text-highlight'>100%</span>);
      const sol = getLiquiditySOL(liquidityB);
      if(sol === 0) return "-";
      else {
        //  return makePercent(Math.sqrt(sol/79.005), 1) + "%";
          return makePercent((sol/79.005), 1) + "%";

      }
    }

    function styleComplete(complete){
      if(complete === 1) return 'text-highlight';
      return "";
    }

    function isEmptyObject(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    function filter_type(){
        if(isEmptyObject(filter)) return "king_hill";
        else return filter["type"];
    }

    function title_time(){
       const _filter_type = filter_type();
       if(_filter_type === "king_hill" ) return "King Time";
       if(_filter_type === "new_token" ) return "Launch Time";
       if(_filter_type === "complete" ) return "King Time";

       return "-";
    }

    function track_time(item){
      
        const _filter_type = filter_type();
       if(_filter_type === "king_hill" ) return varTime(item.king_of_the_hill_timestamp / 1000);
       if(_filter_type === "complete" ) return varTime(item.king_of_the_hill_timestamp / 1000);
       if(_filter_type === "new_token" ) return varTime(item.created_timestamp / 1000);
       return "-";
    }

    function displayComplete(value){
      if(value === 0) return (<div className="text-gray-500"><i className="fa-solid fa-circle text-xs mr-2"></i> No</div>);
      else return (<div className="text-highlight"><i className="fa-solid fa-circle text-xs color-good mr-2"></i> Done</div>);
    }


    return (
       
        <div className="container mx-auto md:mt-4 max-sm:p-4">
            <div className="md:hidden flex justify-center mb-4 gap-4">
                <Navigation2/>
            </div>

            <div className="flex" key="filter">
              <div className="max-sm:w-full x-board px-2 py-1 flex gap-2">
                  <label className="flex items-center cursor-pointer">
                        <input 
                          type="checkbox" className='mr-1'
                          name="king_hill"
                          checked={checkedItems.king_hill}
                          onChange={handleChange}

                        />
                        King of Hill
                    </label>

                    <label className="flex items-center cursor-pointer">
                        <input 
                          type="checkbox" className='mr-1'
                          name="complete"
                          checked={checkedItems.complete}
                          onChange={handleChange}

                        />
                        Complete
                    </label>


                    <label className="flex items-center cursor-pointer">
                        <input 
                          type="checkbox" className='mr-1'
                          name="new_token"
                          checked={checkedItems.new_token}
                          onChange={handleChange}

                        />
                        New
                    </label>
              </div>
            </div>
            <div className="overflow-x-auto	pb-10">
              <table className="table-auto w-full  mt-4 table-tokens" key="list">
                <thead> 
                    <tr>
                      <th className="border border-gray-600 x-2 py-1 sticky left-0  z-10 bg-main">IC</th>
                      <th className="border border-gray-600 px-2 py-2">Name</th>
                      <th className="border border-gray-600 px-2 py-2 max-sm:hidden">Symbol</th>
                      <th className="border border-gray-600 px-2 py-2 max-sm:hidden">C/A</th>
                     
                      <th className="border border-gray-600 px-2 py-2">{title_time()}</th>
                      <th className="border border-gray-600 px-2 py-2">Complete</th>
                      
                      <th className="border border-gray-600 px-2 py-2"><div className='md:flex items-center justify-center'><div>Liquid</div><div className="text-xs md:ml-2 text-slate-400">(SOL)</div> </div></th>
                      <th className="border border-gray-600 px-2 py-2">% Target</th>
                      {/* <th className="border border-gray-600 px-2 py-2">King Hill</th> */}
                    </tr>
                </thead>
                <tbody className='x-board'>
                {dataList.map((item, index) => {
                          var tr_class = '';
                          if(item.complete === 1){
                              tr_class = 'bg-slate-900';
                          }

                          return (
                            
                              <tr key={index} className={`hover:bg-slate-900 cursor-pointer x-border tr-token ${tr_class}`} onDoubleClick={(event) => {doubleClick(event, item.mint)}} onMouseDown={(event) => {openLink(event, item.mint)}}>
                                    
                                    <td className="px-2 py-2  left-0  max-sm:sticky max-sm:z-10 bg-stick">
                                        <img src={item.image_uri} className="token-image-ic" alt={item.name} /></td>
                                    <td className="px-2 py-2">{item.name}</td>
                                    <td className="px-2 py-2 max-sm:hidden">{item.symbol}</td>

                                    <td className="px-2 py-2 max-sm:hidden">{shortAddress(item.mint)}</td>
                                  
                                    <td className="px-2 py-2 min-w-20 color-good">{track_time(item)}</td>
                                    <td>{displayComplete(item.complete)}</td>
    
                                    <td className="max-sm:px-2 max-sm:py-1 md:px-4">
                                        <div className={styleComplete(item.complete)}>{getLiquiditySOL(item.bonding_curve)}</div>
                                      
                                    </td>
                                    <td> <div className='w-full'>{calculateCurve(item.bonding_curve, item.complete)}</div></td>
                              </tr> 
                            
                          )
                  })}
                  </tbody>
              </table>
            </div>

            <div className="w-full mx-auto  text-center fixed bottom-0 left-0 right-0 bg-head text-highlight p-1"> 
                <div className="justify-self-end">
                    <div className="flex justify-center space-x-2">
                        <button className="text-sm hover:font-bold hover:bg-transparent hover:text-slate-50" onClick={prevPage}>[ &lt;&lt; ]</button>
                        <span>{page}</span>
                        <button className="text-sm hover:font-bold hover:bg-transparent hover:text-slate-50" onClick={nextPage}>[ &gt;&gt; ]</button>
                    </div>
                </div>
            </div>

            <Modal isOpen={isModalOpen} onClose={closeModal} />

        </div>
    )
}

export default PumpsArea