import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function AnalysisHead() {
    const [activeItem, setActiveItem] = useState('analysis'); // initialize state
    const location = useLocation(); // get current URL

    useEffect(() => {
        if (location.pathname === '/analysis/') {
          setActiveItem('analysis');
        } 
       
      }, [location]); // update when location changes

      const handleMenuClick = (event, path) => {
        event.preventDefault();
        if (location.pathname === path) {
            window.location.reload();
        } else {
            window.location.href = path;
        }
    };


    return (
        //navigation
        <div className='flex justify-center mt-4 gap-4'>
            <div>
                <button onClick={(e) => handleMenuClick(e, '/analysis/')} className={activeItem === 'analysis' ? 'text-highlight cursor-pointer' : 'cursor-pointer'}>ANALYSIS</button>
            </div>
           
        </div>
    );

}
