import React from 'react';
import TokensArea from './TokensArea';
import Header from '../../components/header/Header';
function Tokens() {
    return (
      <>
        <Header/>
        <main>
          <TokensArea/>
        </main>
      </>
    )
  }
  
  export default Tokens