import React, { useState, useEffect , PureComponent} from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ScatterChart, Scatter } from 'recharts';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const AnalysisArea = () => {
  const [tokenDay, setTokenDay] = useState([]);

  useEffect(() => {
    fetch('https://api.tokencheck.io/analysis/day/60')
        .then(response => {
          // Xử lý khi nhận được response từ server
          return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
        })
        .then(data => {
          // Xử lý dữ liệu đã nhận được từ server
            // console.log(data);
            setTokenDay(data);
        })
        .catch(error => {
          // Xử lý khi gặp lỗi trong quá trình fetch
          console.log('Fetch Error:', error);
        });
        
  }, []);

  const [pumpDay, setPumpDay] = useState([]);

  useEffect(() => {
    fetch('https://tool.tokencheck.io/analysis/pump/60')
        .then(response => {
          // Xử lý khi nhận được response từ server
          return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
        })
        .then(data => {
          // Xử lý dữ liệu đã nhận được từ server
            // console.log(data);
            setPumpDay(data);
        })
        .catch(error => {
          // Xử lý khi gặp lỗi trong quá trình fetch
          console.log('Fetch Error:', error);
        });
        
  }, []);

  const [pinkDay, setPinkDay] = useState([]);

  useEffect(() => {
    fetch('https://tool.tokencheck.io/analysis/pink/60')
        .then(response => {
          // Xử lý khi nhận được response từ server
          return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
        })
        .then(data => {
          // Xử lý dữ liệu đã nhận được từ server
            // console.log(data);
            setPinkDay(data);
        })
        .catch(error => {
          // Xử lý khi gặp lỗi trong quá trình fetch
          console.log('Fetch Error:', error);
        });
        
  }, []);

  const [moonNewDay, setMoonNewDay] = useState([]);

  useEffect(() => {
    fetch('https://tool.tokencheck.io/analysis/moonshot-new/60')
        .then(response => {
          // Xử lý khi nhận được response từ server
          return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
        })
        .then(data => {
          // Xử lý dữ liệu đã nhận được từ server
            // console.log(data);
            setMoonNewDay(data);
        })
        .catch(error => {
          // Xử lý khi gặp lỗi trong quá trình fetch
          console.log('Fetch Error:', error);
        });
        
  }, []);

  // thống kê pool-SOL
  const [solData, setSolData] = useState([]);

  const [value, setValue] = useState(100);

  const handleSliderChange = (newValue) => {
      setValue(newValue);
  };

/*
  useEffect(() => {
    fetch('https://tool.tokencheck.io/analysis/sol/' + value)
        .then(response => {
          // Xử lý khi nhận được response từ server
          return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
        })
        .then(data => {
          // Xử lý dữ liệu đã nhận được từ server
            // console.log(data);
            setSolData(data);
        })
        .catch(error => {
          // Xử lý khi gặp lỗi trong quá trình fetch
          console.log('Fetch Error:', error);
        });
        
  }, [value]);
*/

  const [pumpNewDay, setPumpNewDay] = useState([]);
  // pump new 
  useEffect(() => {
    fetch('https://tool.tokencheck.io/analysis/pump-new/60')
        .then(response => {
          // Xử lý khi nhận được response từ server
          return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
        })
        .then(data => {
          // Xử lý dữ liệu đã nhận được từ server
            // console.log(data);
            setPumpNewDay(data);
        })
        .catch(error => {
          // Xử lý khi gặp lỗi trong quá trình fetch
          console.log('Fetch Error:', error);
        });
        
  }, []);
      
  return (
          <div className="mx-auto mt-4 p-4">
              <div className="grid gap-4">
                    <div>
                        <div className="text-xl text-center">Pair / day</div>
                        <div style={{ width: '100%', height: 300 }}>
                          <ResponsiveContainer>
                              <BarChart width={150} height={40} data={tokenDay}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="blockDate" />
                                  <YAxis />
                                  <YAxis />
                                  <Tooltip />
                                  <Legend />

                                  <Bar dataKey="amount" fill="#8884d8" />
                                  <Bar dataKey="remove" fill="#FF0000" />
                                  <Bar dataKey="burn" fill="#008000" />
                              </BarChart>                              
                          </ResponsiveContainer>
                        </div>
                    </div>

                    <div>
                        <div className="text-xl text-center">Pump.fun / day</div>
                        <div style={{ width: '100%', height: 300 }}>
                          <ResponsiveContainer>
                              <BarChart width={150} height={40} data={pumpDay}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="blockDate" />
                                  <YAxis />
                                  <YAxis />
                                  <Tooltip />
                                  <Legend />

                                  <Bar dataKey="amount" fill="#8884d8" />
                              </BarChart>
                          </ResponsiveContainer>
                        </div>
                    </div>

                    <div>
                        <div className="text-xl text-center">Pump new/ day</div>
                        <div style={{ width: '100%', height: 300 }}>
                          <ResponsiveContainer>
                              <BarChart width={150} height={40} data={pumpNewDay}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="created_date" />
                                  <YAxis />
                                  <YAxis />
                                  <Tooltip />
                                  <Legend />

                                  <Bar dataKey="amount" fill="#8884d8" />
                              </BarChart>
                          </ResponsiveContainer>
                        </div>
                    </div>

                    <div>
                        <div className="text-xl text-center">Moonshot new/ day</div>
                        <div style={{ width: '100%', height: 300 }}>
                          <ResponsiveContainer>
                              <BarChart width={150} height={40} data={moonNewDay}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="blockDate" />
                                  <YAxis />
                                  <YAxis />
                                  <Tooltip />
                                  <Legend />

                                  <Bar dataKey="amount" fill="#8884d8" />
                              </BarChart>
                          </ResponsiveContainer>
                        </div>
                    </div>


                    <div>
                        <div className="text-xl text-center">Pinksale / day</div>
                        <div style={{ width: '100%', height: 300 }}>
                          <ResponsiveContainer>
                              <BarChart width={150} height={40} data={pinkDay}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="blockDate" />
                                  <YAxis />
                                  <YAxis />
                                  <Tooltip />
                                  <Legend />

                                  <Bar dataKey="amount" fill="#8884d8" />
                              </BarChart>
                          </ResponsiveContainer>
                        </div>
                    </div>

                    {/* <div>
                        <div className="text-xl text-center flex mx-auto w-full">
                          <div style={{ width: '400px', height : '60px', margin: '50px auto' }}>
                              <div>Pool SOL</div>
                              <div> <Slider
                                  min={0}
                                  max={1000}
                                  value={value}
                                  onChange={handleSliderChange}
                                  marks={{ 0: '0', 200: '200', 400: '400', 600: '600', 800: '800', 1000: '1000' }}
                                  step={1}

                              /></div>
                            </div>
                        </div>
                        <div style={{ width: '100%', height: 300 }}>
                          <ResponsiveContainer>
                              <ScatterChart width={150} height={40}>
                              <CartesianGrid />
                              <XAxis type="number" dataKey="intSol" name="SOL" unit="" />
                              <YAxis type="number" dataKey="amount" name="Tokens" unit="" />
                              <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                              <Scatter name="Pool SOL" data={solData} fill="#8884d8" />

                              </ScatterChart>
                          </ResponsiveContainer>
                        </div>
                    </div> */}
              </div>
          </div>

  );
}

export default AnalysisArea