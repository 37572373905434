import React from 'react';
import AnalysisArea from './AnalysisArea';
import Header from '../../components/header/Header';
import AnalysisHead from './AnalysisHead';
function Analysis() {
    return (
      <>
        <Header/>
        <main>
          <AnalysisHead/>
          <AnalysisArea/>
        </main>
      </>
    )
  }
  
  export default Analysis